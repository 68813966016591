export enum RELATED_FLAG {
  SITE = 'SITE',
  PHYSICIAN = 'PHYSICIAN',
}

export enum FLAG_TYPE {
  ORDER_ACTION = 'ORDER_ACTION',
  RADIOLOGIST = 'RADIOLOGIST',
  TRANSCRIPTION = 'TRANSCRIPTION',
}

export const FLAG_NAME_MAX = 100
export const FLAG_DESCRIPTION_MAX = 2500
