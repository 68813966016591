import { FC, PropsWithChildren, useEffect } from 'react'
import { Box } from '@mui/material'

interface IPSTabPanelContentProps {
  padding?: number
  onTabContentMounted?: () => void
}

export const PSTabPanelContent: FC<
  PropsWithChildren<IPSTabPanelContentProps>
> = ({ padding, onTabContentMounted, children }) => {
  useEffect(() => {
    onTabContentMounted?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Box p={padding}>{children}</Box>
}
