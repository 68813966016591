import { RootState } from 'store/store'

const getDestinationsSlice = (state: RootState) => state.destinations

export const getDestinationLoadingState = (state: RootState) =>
  getDestinationsSlice(state).destinationLoadingState

export const getDestinationsTableData = (state: RootState) =>
  getDestinationsSlice(state).destinationsTableData

export const getDestinationList = (state: RootState) =>
  getDestinationsTableData(state).content

export const getDestinationGroupsTableData = (state: RootState) =>
  getDestinationsSlice(state).destinationGroupsTableData

export const getDestinationGroupLoadingState = (state: RootState) =>
  getDestinationsSlice(state).destinationGroupLoadingState
