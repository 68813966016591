export enum COUNTRY_CODES {
  EMPTY = '',
  // ATM country is required fields on the BE. For the short forms to create a provider BE-FE-BA agreed to send XX value
  // to the BE side to let them know to return this field with null value when we get back a data of created provider
  UI_FORM_DOESNT_PROVIDE_FIELD_BUT_SERVER_EXPECTS_THE_VALUE = 'XX',
  US = 'US',
}

export enum STATE_CODES {
  EMPTY = '',
  OH = 'OH',
}
