import { IState } from 'store/flags/types'
import { LOADING_STATE } from 'types/general'

export const initialState: IState = {
  list: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    first: true,
    last: false,
  },
  loadingState: LOADING_STATE.idle,
  error: null,
  flag: undefined,
}

export const name = 'flags'
