import { string, StringSchema } from 'yup'
import i18n from 'i18n/index'

import { validateNotEmptyStringLengthOrNull } from '../validators/validateNotEmptyStringLengthOrNull'

export const minNotEmpty = (minLength: number) =>
  minNotEmptyTest(string().trim(), minLength)

export const minNotEmptyTest = (yupString: StringSchema, minLength: number) =>
  yupString.test(
    'min-validation',
    i18n.t('common.validation.validationCharsMin', { min: minLength }),
    validateNotEmptyStringLengthOrNull(minLength)
  )
