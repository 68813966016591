import { RootState } from 'store/store'

import { TFlagInfo, TFlagsPageResponse } from './types'

const getFlags = (state: RootState) => state.flags

export const getFlagInfo = (state: RootState): TFlagInfo | undefined =>
  getFlags(state).flag

export const getFlagsError = (state: RootState) => getFlags(state).error

export const getFlagsLoadingState = (state: RootState) =>
  getFlags(state).loadingState

export const getFlagsTableData = (state: RootState): TFlagsPageResponse =>
  getFlags(state).list
