import { useCallback } from 'react'
import { IOrderTableQueryData } from 'store/filters/orders/types'
import { useAppDispatch } from 'store/hooks'
import { ORDER_STATUS_ENUM } from 'store/order/types'
import { getOrders } from 'store/ordersList/controllers'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'

import { mapSearchFiltersToBackEndParams } from '../helpers/mapSearchFiltersToBackEndParams'
import { TFilterParams } from '../types'

export const useGetOrders = (tableName: ORDERS_TABLE_TAB) => {
  const dispatch = useAppDispatch()

  const fetchOrders = useCallback(
    ({
      orderStatus,
      filtersData,
      queryData,
      abortSignal,
    }: {
      orderStatus?: ORDER_STATUS_ENUM
      filtersData: TFilterParams
      queryData: IOrderTableQueryData
      abortSignal?: AbortSignal
    }) => {
      dispatch(
        getOrders({
          tableName,
          params: {
            ...mapSearchFiltersToBackEndParams(filtersData),
            ...(orderStatus && { orderStatus }),
            ...queryData,
            isPaged: true,
          },
          abortSignal,
        })
      )
    },
    // eslint-disable-next-line
    [tableName]
  )

  return { fetchOrders }
}
