import { RootState } from 'store/store'
import { LOADING_STATE } from 'types/general'

const getSupportSlice = (state: RootState) => state.support

export const getSupportTableData = (state: RootState) =>
  getSupportSlice(state).table

export const getSupportWorkEntity = (state: RootState) =>
  getSupportSlice(state).entity

export const getSupportWorkType = (state: RootState) =>
  getSupportWorkData(state)?.type

export const getSupportWorkStatus = (state: RootState) =>
  getSupportWorkData(state)?.status

export const getSupportWorkComments = (state: RootState) =>
  getSupportSlice(state).comments

export const getSupportWorkData = (state: RootState) =>
  getSupportWorkEntity(state).data

export const getSupportWorkIsLoading = (state: RootState) => {
  const {
    loadingState,
    isTargetGroupUpdating,
    isStatusUpdating,
    isTypeUpdating,
  } = getSupportWorkEntity(state)

  return {
    isSupportRequestLoading: loadingState === LOADING_STATE.pending,
    isTargetGroupUpdating,
    isStatusUpdating,
    isTypeUpdating,
  }
}

export const getTableIsFilterPanelOpen = (state: RootState) =>
  getSupportTableData(state).isFilterPanelOpen
