import { TProviderDisplayName } from 'store/providers/types'

export const getProviderOptionLabel = (
  radiologist: TProviderDisplayName,
  withDegrees: boolean = true
) => {
  const { firstName, lastName, middleName, degrees = [] } = radiologist

  const initials = `${lastName ?? ''}, ${firstName ?? ''} ${(
    middleName ?? ''
  ).charAt(0)}`.trim()
  const degreesString = degrees.map((degree) => degree.name).join(', ')
  const degreesRenderString = withDegrees ? ` ${degreesString}` : ''

  return `${initials}${degreesRenderString}`.trim()
}
