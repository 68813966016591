import { cloneElement, FC } from 'react'
import _noop from 'lodash/noop'

import {
  StyledActionButtonContainer,
  StyledButtonWrapper,
  StyledProgressIcon,
  StyledTextWithActionContainer,
} from './PSButtonWithAction.styled'

interface IPSButtonWithActionProps {
  variant?: 'text' | 'button'
  content: JSX.Element
  isLoading?: boolean
  loaderSize?: number
  onClick: () => void
}

export const PSButtonWithAction: FC<IPSButtonWithActionProps> = ({
  onClick,
  loaderSize = 24,
  content,
  isLoading = false,
  variant = 'button',
}) => {
  const handleOnClick = isLoading ? _noop : onClick

  if (variant === 'text') {
    return (
      <StyledTextWithActionContainer
        variant='text'
        onClick={handleOnClick}
        isLoading={isLoading}
      >
        <StyledButtonWrapper isLoading={isLoading}>
          {content}
        </StyledButtonWrapper>
        {isLoading && <StyledProgressIcon size={loaderSize} />}
      </StyledTextWithActionContainer>
    )
  }

  const clonedButton = cloneElement(content, { onClick: handleOnClick })

  return (
    <StyledActionButtonContainer isLoading={isLoading}>
      <StyledButtonWrapper isLoading={isLoading}>
        {clonedButton}
      </StyledButtonWrapper>
      {isLoading && <StyledProgressIcon size={loaderSize} />}
    </StyledActionButtonContainer>
  )
}
