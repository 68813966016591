/* eslint-disable */
// eslint wants to use single quotes for strings that contain single-quotes
// but prettier forces double quotes

export const components = {
  buttons: {
    noMatch: 'No Match',
    merge: 'Merge',
    restrict: 'Restrict',
    initiate: 'Initiate',
    request: 'Request',
    complete: 'Complete',
    enable: 'Enable',
    share: 'Share',
    revert: 'Revert',
    exclude: 'Exclude',
    apply: 'Apply',
    cancel: 'Cancel',
    confirm: 'Confirm',
    doNotSave: "Don't Save",
    save: 'Save',
    submit: 'Submit',
    archive: 'Archive',
    unarchive: 'Unarchive',
    proceed: 'Proceed',
    correctPatientInfo: 'Correct patient info',
    update: 'Update',
    saveChanges: 'Save changes',
    clearAll: 'Clear All',
    reset: 'Reset',
    next: 'Next',
    previous: 'Previous',
    selectAll: 'Select All',
    select: 'Select',
    unselect: 'Unselect',
    showMore: 'Show more',
    showLess: 'Show less',
    colorModeDark: 'Dark mode',
    colorModeLight: 'Light mode',
    yes: 'Yes',
    no: 'No',
    edit: 'Edit',
    add: 'Add',
    uploadFiles: 'Upload Files',
    downloadAll: 'Download all',
    listFilters: 'Filters',
    disassociate: 'Disassociate',
    disassociateAll: 'Disassociate all',
    uploadStudy: 'Upload Study',
    upload: 'Upload',
    clearAllButton: 'Clear all',
    searchButton: 'search',
    addButton: 'Add',
    leavePage: 'Leave page',
    addNew: 'Add New',
    onCallAssignment: 'On-call assignment',
    addAssignment: 'Add Assignment',
    addProvider: 'Add Provider',
    addNewProvider: 'Add New Provider',
    remove: 'Remove',
    moreActions: 'More actions',
    showAll: 'Show All',
    exportTemplate: 'Export Template',
    agree: 'Agree',
    okay: 'okay',
    delete: 'Delete',
    search: 'Search',
    support: 'Support',
    resolve: 'Resolve',
    resolved: 'Resolved',
    reopen: 'Reopen',
    assign: 'Assign',
    markDelivered: 'Mark Delivered',
    csvReport: 'CSV Report',
    pdfReport: 'PDF Report',
    expandAll: 'Expand all sections',
    collapseAll: 'Collapse all sections',
    openStudiesInInteleconect: 'Open Studies in Inteleconect',
    downloadISOFile: 'Download Primary Study ISO',
    pushStudiesInPACS: 'Push Studies in PACS',
    resendHL7: 'Resend HL7 to the downstream system',
    downloadReport: 'Download Report',
    create: 'Create',
    seeAuditLog: 'See Audit Log',
    seePatientAuditLog: 'See Patient Audit Log',
    searchPatientRecord: 'Search Patient Record',
    addFax: 'Add Fax',
    send: 'Send',
    resetSchedule: 'Reset Schedule',
    attach: 'Attach',
    applyAndMarkDelivered: 'Apply and Marked Delivered',
  },
  inputs: {
    search: 'search',
    searchWithDots: 'Search ...',
    charactersLeft_one: 'character left',
    charactersLeft_other: 'characters left',
    charactersMin: '(min {{n}})',
  },
  titles: {
    location: 'Location',
    notFoundPageTitle: '404 Error',
    accessDeniedPageTitle: '403 Error',
    sessionExpirationTitle: 'Are you still here?',
    companyTitle: 'Company',
    listFiltersTitle: 'Filters',
  },
  sidebar: {
    sidebarItemDashboard: 'Dashboard',
    sidebarItemOrders: 'RIS-Orders',
    sidebarItemUserManagement: 'Users',
    sidebarItemCompanies: 'Companies',
    sidebarItemPractices: 'Practices',
    sidebarItemProviders: 'Providers',
    sidebarItemSupport: 'Support',
    sidebarItemDictionaries: 'Dictionaries',
    sidebarItemPatients: 'Patients',
    sidebarItemSharedStudies: 'Shared Studies',
  },
  table: {
    rowsPerPage: 'Rows per page:',
    noItemsFound: 'No items found',
    notApplicable: 'n/a',
  },
  labels: {
    dateFromLabel: 'From:',
    dateToLabel: 'To:',
    NDCCodeLabel: 'NDC Code',
    ICDCodeLabel: 'ICD-10 Code',
    view: 'View',
    uploadDocumentInFormat: 'Upload or drop a document in {{formats}}',
    uploadDocumentsWithTotalSize:
      'Upload or drop a document in {{formats}}. Total size of all files {{size}} {{unit}} ',
    countryFilter: 'Country',
    stateFilter: 'State',
    uploadDICOMFiles:
      'Upload or drop folders that contains DICOM files. Max size {{maxFileSize}} GB. Total size of all studies {{totalMaxSize}} GB  ',
  },
  modals: {
    unsavedHeader: 'Unsaved Changes',
    unsavedDialog: 'Would you like to save changes before leaving?',
    uploadingFilesHeader: 'Files are uploading',
    warningFilesAreUploadingHeader: 'Warning: Files are still being uploaded',
    leaveWithoutUploadingDialog:
      'Would you like to leave the page without all uploaded fields? Click "Cancel" to close the pop-up and continue upload process or click "Leave page" to leave the page without the files that have not fully uploaded.',
    saveWithoutUploadingDialog:
      'Would you like to save the order without all uploaded fields? Click "Cancel" to close the pop-up and continue upload process or click "Save" to save the order without the files that have not fully uploaded.',
    submitWithoutUploadDialog:
      'Would you like to submit the order without all uploaded fields? Click "Cancel" to close the pop-up and continue upload process or click "Submit" to submit the order without the files that have not fully uploaded.',
    logoutLabel: 'Logout',
    logoutMessage: 'Are you sure you want to log out from your account?',
    logoutAcceptButton: 'Log out',
    deleteHeader: 'Delete {{entityName}}',
    deleteText: 'Are you sure you want to delete {{entityName}}?',
    removeHeader: 'Remove {{entityName}}',
    removeFromText:
      'Are you sure you want to remove {{childEntity}} from {{parentEntity}}?',
  },
  notifications: {
    successHeader: 'Success',
    errorHeader: 'Error',
  },
}
