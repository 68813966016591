import { createAsyncThunk } from '@reduxjs/toolkit'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import { DASHBOARD_SLICE_NAME } from './constants'
import {
  TAdminDashboardRs,
  TAdminDuplicatesInfoRs,
  TBffCompanyDashboardContentRs,
  TBffProviderAssistantDashboardRs,
  TDuplicatesDataQuery,
} from './types'

export const fetchDashboardInfo =
  createAsyncThunk<TBffCompanyDashboardContentRs>(
    `${DASHBOARD_SLICE_NAME}/fetchDashboardInfo`,
    async (_, { rejectWithValue }) => {
      const result = await request<TBffCompanyDashboardContentRs>({
        url: '/bff/companies/dashboard',
        method: 'GET',
      })
      if (result.error) {
        return rejectWithValue(result.error)
      }

      return result.data
    }
  )

export const fetchSuperUserDashboardInfo = createAsyncThunk<TAdminDashboardRs>(
  `${DASHBOARD_SLICE_NAME}/fetchSuperUserDashboardInfo`,
  async (_, { rejectWithValue }) => {
    const result = await request<TAdminDashboardRs>({
      url: '/bff/dashboards/admin',
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchSuperUserDuplicatesCount = createAsyncThunk<
  TAdminDuplicatesInfoRs,
  TDuplicatesDataQuery,
  { rejectValue: TServiceError }
>(
  `${DASHBOARD_SLICE_NAME}/fetchSuperUserDuplicatesCount`,
  async (params, { rejectWithValue }) => {
    const result = await request<TAdminDuplicatesInfoRs>({
      url: '/bff/dashboards/admin/patient-potential-duplicate',
      params,
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchProviderAssistantDashboardInfo =
  createAsyncThunk<TBffProviderAssistantDashboardRs>(
    `${DASHBOARD_SLICE_NAME}/fetchProviderAssistantDashboardInfo`,
    async (_, { rejectWithValue }) => {
      const result = await request<TBffProviderAssistantDashboardRs>({
        url: '/bff/dashboards/provider-assistant',
        method: 'GET',
      })

      if (result.error) {
        return rejectWithValue(result.error)
      }

      return result.data
    }
  )
