import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SxProps, Theme, Typography } from '@mui/material'
import { DATE_TIME_FORMAT } from 'constants/dates/dateFormats'
import dayjs from 'dayjs'
import { formatDateToPattern } from 'utils/helpers/date/formatDateToPattern'

interface IDateTimeLocaleCellProps {
  data?: string
  cellStyles?: SxProps<Theme>
}

export const DateTimeLocaleCell: FC<IDateTimeLocaleCellProps> = ({
  data,
  cellStyles,
}) => {
  const { t } = useTranslation()

  return (
    <Typography variant='body2' sx={cellStyles}>
      {data
        ? formatDateToPattern(DATE_TIME_FORMAT, dayjs.utc(data).local())
        : t('components.table.notApplicable')}
    </Typography>
  )
}
