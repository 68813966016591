import { LOADING_STATE, TQueryData } from 'types/general'
import { components } from 'types/schemas'

export type TOrderAuditTableResponse = Omit<
  components['schemas']['OrderAuditPageRs'],
  'content'
> & { content: TOrderAuditItem[] }

export type TPatientAuditTableResponse = Omit<
  components['schemas']['PatientAuditPageRs'],
  'content'
> & { content: TPatientAuditItem[] }

export type TOrderCategory = Omit<
  components['schemas']['OrderCategoryRs'],
  'category'
> & {
  category: ORDER_AUDIT_CATEGORY_ENUM
}

export type TPatientCategory = Omit<
  components['schemas']['PatientCategoryRs'],
  'category'
> & { category: PATIENT_AUDIT_CATEGORY_ENUM }

export type TCategory = TOrderCategory | TPatientCategory

export type TOrderEvent = Omit<
  Required<components['schemas']['OrderEventRs']>,
  'category'
> & {
  category: ORDER_AUDIT_CATEGORY_ENUM
}

export type TPatientEvent = Omit<
  Required<components['schemas']['PatientEventRs']>,
  'category'
> & {
  category: PATIENT_AUDIT_CATEGORY_ENUM
}

export type TEvent = TOrderEvent | TPatientEvent

export type TTrigger = Required<components['schemas']['TriggerRs']>

export type TAuditTableCategoryAndEventFilterNames = Extract<
  keyof IAuditFiltersData,
  'categories' | 'events'
>

export type TOrderAuditItem = Omit<
  components['schemas']['OrderAuditRs'],
  'time' | 'oldValue' | 'category'
> & {
  time?: string
  oldValue: string | null
  category: TOrderCategory
}

export type TPatientAuditItem = Omit<
  components['schemas']['PatientAuditRs'],
  'time' | 'oldValue' | 'category'
> & {
  time?: string
  oldValue: string | null
  category: TPatientCategory
}

export interface IAuditFiltersData {
  categories: TCategory[]
  triggers: TTrigger[]
  events: TEvent[]
  dateTime: {
    from: string | null
    to: string | null
  }
  companyId?: string
}

export interface IAuditTableParams extends IAuditFiltersData, TQueryData {}

type TAuditTable<T, K> = Omit<T, 'first' | 'last' | 'content'> & {
  content: K[]
  isFirstPage: boolean
  isLastPage: boolean
}

export type TOrderAuditTableData = TAuditTable<
  TOrderAuditTableResponse,
  TOrderAuditItem
>

export type TPatientAuditTableData = TAuditTable<
  TPatientAuditTableResponse,
  TPatientAuditItem
>

type TBasicAuditData = {
  queryData: TQueryData
  isFilterPanelOpen: boolean
  loadingState: LOADING_STATE
  filtersData: IAuditFiltersData
}

export type TOrderAuditData = {
  tableData: TOrderAuditTableData
} & TBasicAuditData

export type TPatientAuditData = {
  tableData: TPatientAuditTableData
} & TBasicAuditData

export enum AUDIT_SLICE_KEY {
  order = 'order',
  patient = 'patient',
}

export interface IState {
  [AUDIT_SLICE_KEY.order]: TOrderAuditData
  [AUDIT_SLICE_KEY.patient]: TPatientAuditData
}

export enum ORDER_AUDIT_CATEGORY_ENUM {
  PATIENT = 'PATIENT',
  REF_MD = 'REF_MD',
  ASSIGNMENT = 'ASSIGNMENT',
  DOCUMENTS = 'DOCUMENTS',
  ORDER_CONTENT_UPDATE = 'ORDER_CONTENT_UPDATE',
  ORDER_TRANSITIONS = 'ORDER_TRANSITIONS',
  BILLING_INFO_UPDATE = 'BILLING_INFO_UPDATE',
  BILLING_TRANSITIONS = 'BILLING_TRANSITIONS',
  REPORT_UPDATE = 'REPORT_UPDATE',
  REPORT_DELIVERY = 'REPORT_DELIVERY',
  REPORT = 'REPORT',
  TRANSITION_RULE = 'TRANSITION_RULE',
  TRANSITION_REASON = 'TRANSITION_REASON',
  RVU = 'RVU',
  PRIMARY_STUDY = 'PRIMARY_STUDY',
  EXAM_PAPERWORK = 'EXAM_PAPERWORK',
  COMPARISON_INFORMATION = 'COMPARISON_INFORMATION',
  IMAGES_DELIVERY = 'IMAGES_DELIVERY',
  IMAGES_SHARING = 'IMAGES_SHARING',
  DICOM_STUDIES = 'DICOM_STUDIES',
  TAG = 'TAG',
  SUPPORT_REQUEST = 'SUPPORT_REQUEST',
  OUTBOUND_HL7 = 'OUTBOUND_HL7',
  INBOUND_HL7 = 'INBOUND_HL7',
  EXAM_NOTE = 'EXAM_NOTE',
}

export enum PATIENT_AUDIT_CATEGORY_ENUM {
  PATIENT = 'PATIENT',
  BILLING_INFO = 'BILLING_INFO',
  OUTBOUND_HL7 = 'OUTBOUND_HL7',
  MDM_LINK = 'MDM_LINK',
}

export enum HL7_COMMUNICATION_TYPE_ENUM {
  IN = 'IN',
  OUT = 'OUT',
}

export type THl7MessageResponse = components['schemas']['Hl7MessageRs']
