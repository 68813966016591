import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import {
  BILLING_FOR_SITE_TAG,
  LIST_ID,
  PAPERWORKS_TAG,
  PATIENTS_TAG,
  PHYSICIANS_TAG,
  PROCEDURES_TAG,
  SITE_TAG,
  USERS_TAG,
} from 'services/bff/constants'
import { TBillingType } from 'services/dictionary/types'
import { TOrdersTableParams } from 'store/filters/orders/types'
import { TProceduresFilters } from 'store/filters/procedures/types'
import { INSURANCE_TYPES_ENUM } from 'store/orderBillingInformation/types'
import { prepareTableQueryParamsForSend } from 'store/ordersList/helpers/prepareTableQueryParamsForSend'
import { ORDERS_TABLE_TAB, TOrdersResponse } from 'store/ordersList/types'
import { PROCEDURE_STATUS } from 'store/procedures/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TPageable, TQueryData } from 'types/general'

import {
  TBffCheckNeedUpdateRs,
  TBffPageUserRs,
  TBffPaperworkRs,
  TBffPaperWorksPageRs,
  TBffProcedurePageRs,
  TGetPatientListParams,
  TGetPatientsListResponse,
  TPaperworkRs,
  TPaperWorksTableFilter,
  TPatientsResponse,
  TPhysiciansPageResponse,
  TPhysiciansTableFilter,
  TSiteDataResponse,
  TUserTableFilter,
} from './types'

export const bffApi = createApi({
  reducerPath: 'bffApi',
  tagTypes: [
    USERS_TAG,
    PAPERWORKS_TAG,
    PROCEDURES_TAG,
    PHYSICIANS_TAG,
    BILLING_FOR_SITE_TAG,
    SITE_TAG,
    PATIENTS_TAG,
  ],
  baseQuery: axiosBaseQuery({ baseUrl: '/bff' }),
  endpoints: (builder) => ({
    getBffPaperWorks: builder.query<
      TBffPaperWorksPageRs,
      { pageable: TPageable; filter: TPaperWorksTableFilter }
    >({
      query: ({ pageable, filter }) => {
        return {
          url: 'paperworks',
          params: {
            ...pageable,
            ...filter,
            siteIds: prepareArrayForSending(
              filter?.siteIds?.map(({ id }) => id) ?? []
            ),
          },
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...(result.content?.map(({ id }) => ({
                type: PAPERWORKS_TAG,
                id: String(id),
              })) ?? []),
              { type: PAPERWORKS_TAG, id: LIST_ID },
            ]
          : [{ type: PAPERWORKS_TAG, id: LIST_ID }],
    }),
    getBffPaperWorkById: builder.query<TBffPaperworkRs, string>({
      query: (id) => {
        return {
          url: `paperworks/${id}`,
        }
      },
      transformResponse: (response: TPaperworkRs) => {
        const pageDataList = (
          response?.pageDataList ? [...response.pageDataList] : []
        )
          ?.sort((a, b) => a.pageNumber - b.pageNumber)
          .map((paperwork) => ({
            ...paperwork,
            isPageAttached: Boolean(paperwork.site),
            isPageIgnored: Boolean(paperwork.ignored),
            fixedOrders: paperwork.orders,
          }))
        return {
          ...response,
          pageDataList,
        }
      },
      providesTags: (_result, _error, id) => [{ type: PAPERWORKS_TAG, id }],
    }),
    getPhysicians: builder.query<
      TPhysiciansPageResponse,
      { pageable: TQueryData; filter: TPhysiciansTableFilter }
    >({
      query: ({ pageable, filter }) => ({
        url: 'providers/physicians',
        params: {
          ...pageable,
          ...filter,
          siteIds: prepareArrayForSending(
            filter?.siteIds?.map(({ id }) => id) ?? []
          ),
          taxonomyIds: prepareArrayForSending(
            filter?.taxonomyIds?.map(({ id }) => id) ?? []
          ),
          practiceIds: prepareArrayForSending(
            filter?.practiceIds?.map(({ id }) => id) ?? []
          ),
          search: prepareArrayForSending(pageable.search?.split(' ')),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result.content?.map(({ providerId }) => ({
                type: PHYSICIANS_TAG,
                id: String(providerId),
              })) ?? []),
              { type: PHYSICIANS_TAG, id: LIST_ID },
            ]
          : [{ type: PHYSICIANS_TAG, id: LIST_ID }],
    }),
    getUsers: builder.query<
      TBffPageUserRs,
      { pageable: TPageable; filter: TUserTableFilter }
    >({
      query: ({ pageable, filter }) => ({
        url: 'users',
        params: {
          ...pageable,
          ...filter,
          siteIds: prepareArrayForSending(
            filter?.siteIds?.map(({ siteId }) => siteId) ?? []
          ),
          companyIds: prepareArrayForSending(
            filter?.companyIds?.map(({ companyId }) => companyId) ?? []
          ),
          practiceIds: prepareArrayForSending(
            filter?.practiceIds?.map(({ practiceId }) => practiceId) ?? []
          ),
          roles: prepareArrayForSending(
            filter?.roles?.map(({ id }) => id) ?? []
          ),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result.content?.map(({ keycloakUserId }) => ({
                type: USERS_TAG,
                id: keycloakUserId as string,
              })) ?? []),
              { type: USERS_TAG, id: LIST_ID },
            ]
          : [{ type: USERS_TAG, id: LIST_ID }],
    }),
    getProcedures: builder.query<
      TBffProcedurePageRs,
      { pageable?: TPageable; filter?: TProceduresFilters }
    >({
      query: ({ pageable, filter }) => ({
        url: 'orders/procedures',
        params: {
          ...pageable,
          status:
            filter?.status === PROCEDURE_STATUS.ARCHIVED
              ? `${PROCEDURE_STATUS.ARCHIVED},${PROCEDURE_STATUS.ACTIVE}`
              : PROCEDURE_STATUS.ACTIVE,
          modalityIds:
            prepareArrayForSending(filter?.modalities.map(({ id }) => id)) ??
            [],
          bodyPartIds:
            prepareArrayForSending(filter?.bodyParts.map(({ id }) => id)) ?? [],
          cptCodeIds:
            prepareArrayForSending(filter?.cptCodes?.map(({ code }) => code)) ??
            [],
          procedureIds:
            prepareArrayForSending(filter?.procedures?.map(({ id }) => id)) ??
            [],
          subspecialtiesIds:
            prepareArrayForSending(
              filter?.subspecialties?.map(({ id }) => id)
            ) ?? [],
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result.content?.map(({ id }) => ({
                type: PROCEDURES_TAG,
                id: id.toString(),
              })) ?? []),
              { type: PROCEDURES_TAG, id: LIST_ID },
            ]
          : [{ type: PROCEDURES_TAG, id: LIST_ID }],
    }),
    getPatient: builder.query<TPatientsResponse, { id: string }>({
      query: ({ id }) => ({
        url: `patients/info/${id}`,
      }),
    }),
    getSiteById: builder.query<TSiteDataResponse, number>({
      query: (id) => ({ url: `org/sites/${id}` }),
      providesTags: (_result, _error, id) => [{ type: SITE_TAG, id }],
    }),
    getPrimaryBillingTypesBySiteId: builder.query<TBillingType[], number>({
      query: (siteId) => ({
        url: `orders/billing-types?type=${INSURANCE_TYPES_ENUM.PRIMARY}&siteId=${siteId}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result?.map(({ id }) => ({
                type: BILLING_FOR_SITE_TAG,
                id: String(id),
              })) ?? []),
              { type: BILLING_FOR_SITE_TAG, id: LIST_ID },
            ]
          : [{ type: BILLING_FOR_SITE_TAG, id: LIST_ID }],
    }),
    getNeedUpdate: builder.query<TBffCheckNeedUpdateRs, string[]>({
      query: (accessionNumbers) => ({
        url: 'orders/check-need-update',
        params: {
          accessionNumbers: accessionNumbers.join(','),
        },
      }),
    }),
    getOrders: builder.query<
      TOrdersResponse,
      {
        tableName: ORDERS_TABLE_TAB
        params: TOrdersTableParams
      }
    >({
      query: ({
        tableName,
        params: { search, page, size, isPaged, ...searchParams },
      }) => ({
        url: 'orders/bystatuses',
        params: {
          ...prepareTableQueryParamsForSend(searchParams),
          page,
          size,
          isPaged,
          tabName: tableName,
          ...(search && { search: search.replaceAll(',', '') }),
        },
      }),
    }),
    getPatientsList: builder.query<
      TGetPatientsListResponse,
      {
        params: TGetPatientListParams
        abortSignal?: AbortSignal
      }
    >({
      query: ({ params }) => ({
        url: 'orders/patients/grid',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result.content?.map(({ id }) => ({
                type: PATIENTS_TAG,
                id: id,
              })) ?? []),
              { type: PATIENTS_TAG, id: LIST_ID },
            ]
          : [{ type: PATIENTS_TAG, id: LIST_ID }],
    }),
  }),
})

export const {
  useGetBffPaperWorksQuery,
  useGetPhysiciansQuery,
  useGetBffPaperWorkByIdQuery,
  useGetUsersQuery,
  useGetProceduresQuery,
  useGetPatientQuery,
  useGetSiteByIdQuery,
  useGetPrimaryBillingTypesBySiteIdQuery,
  useGetNeedUpdateQuery,
  useGetOrdersQuery,
  useGetPatientsListQuery,
  useLazyGetPatientsListQuery,
} = bffApi
