import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

interface ActiveStatusCellProps {
  data: boolean
}

export const ActiveStatusCell: FC<ActiveStatusCellProps> = ({
  data: isActive,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'domains.Dictionaries',
  })

  return (
    <Typography
      variant='body2'
      color={isActive ? 'success.main' : 'text.disabled'}
    >
      {t(isActive ? 'active' : 'inactive')}
    </Typography>
  )
}
