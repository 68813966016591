import { styled } from '@mui/material'
import { PSButton } from 'components/ui-components/PSButton'

export const StyledPSButton = styled(PSButton)({
  display: 'none',
})

export const StyledForm = styled('form')({
  width: '100%',
})
