import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { DATE_FORMAT_FOR_UI } from 'constants/dates/dateFormats'
import { formatDateToPattern } from 'utils/helpers/date/formatDateToPattern'

interface IDateCellProps {
  data?: string
}

export const DateCell: FC<IDateCellProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Typography variant='body2'>
      {data
        ? formatDateToPattern(DATE_FORMAT_FOR_UI, data)
        : t('components.table.notApplicable')}
    </Typography>
  )
}
