export const Patients = {
  // common
  patientListPageTitle: 'Patients',
  patientPageTitle: 'Patient Page',
  goldenRecord: 'Golden Record',
  // patient page
  proScanMRNTitle: 'ProScan MRN: {{goldenResourceId}}',
  firstNameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  dateOfBirthLabel: 'Date of Birth',
  genderAtBirthLabel: 'Gender at Birth',
  sourceMRN: 'Source MRN',
  ssn: 'SSN',
  phoneNumberBusiness: 'Phone Number – Business',
  phoneNumberHome: 'Phone Number – Home',
  business: 'Business',
  home: 'Home',
  absentTargetOrSelectedPatientId: 'The selected patient ID is absent',
  absentTargetIdOrSourceId: 'Cannot find target or source ID',
  billingInformation: 'Billing Information',

  // patient list page
  searchByNamePlaceholder: 'Search by name',
  showPotentialDuplicatesSwitcherLabel: 'Show records with',
  showDuplicatesPill: 'Show Duplicates',
  mergeRecords: 'Merge records',
  mergeRecordsDescription:
    'Are you sure you want to merge these records? This action will be irrevocable.',
  noMatchDescription:
    'Are you sure you want to indicate this record as No Match? This action will be irrevocable.',
  activeGoldenRecord: 'Active Golden Record',
  potentialDuplicateGoldenRecord: 'Potential Duplicate Golden Record',
  potentialDuplicatePatientRecord: 'Potential Duplicate Patient Record',
  compareDuplicateModalHeader: 'Compare Potential duplicate with Active Record',
  compareDuplicateWithGR: 'Compare Potential duplicates with Golden record',
  company: 'Company',
  matchType: 'Match Type',
  additionalInformation: 'Additional Information',
  accountNumber: 'Account Number',
  centricityID: 'Centricity ID',
  assigningAuthority: 'Assigning Authority: {{assigningAuthority}}',
  mismatchGRField: 'Mismatch: GR value is {{value}}',
  potentialDuplicates: 'Potential Duplicates',
  NO_MATCH: 'No Match',
  AUTO_MATCH: 'Auto',
  MANUAL_MATCH: 'Manual',
  POSSIBLE_MATCH: 'Possible Match',
  POSSIBLE_DUPLICATE: 'Potential Duplicate',
  hasGoldenRecordTooltip: 'Patient record has its own Golden Record',

  //comparison modal
  newRecordLoadedNotification: 'Confirmed, new comparison is loaded.',
  mergeConfirmed: 'Records successfully merged.',
  noMatchConfirmed: 'Record successfully marked as No Match.',
}
