import { FC } from 'react'
import { Tooltip } from '@mui/material'

import { PSButton } from './PSButton'

interface IPSButtonLinkWithTooltipProps {
  buttonText: string
  tooltipText?: string
  isDisabled?: boolean
  startIcon: JSX.Element
  onClick?: () => void
  isArrow?: boolean
  dataCy?: string
}

export const PSButtonLinkWithTooltip: FC<IPSButtonLinkWithTooltipProps> = ({
  buttonText,
  tooltipText,
  startIcon,
  isDisabled = false,
  onClick,
  isArrow,
  dataCy,
}) => {
  return isDisabled ? (
    <Tooltip placement='top' title={tooltipText} arrow={isArrow}>
      <span>
        <PSButton
          size='small'
          variant='text'
          data-cy={dataCy}
          disabled={isDisabled}
          startIcon={startIcon}
          isLabelTransformDisabled
        >
          {buttonText}
        </PSButton>
      </span>
    </Tooltip>
  ) : (
    <PSButton
      size='small'
      variant='text'
      data-cy={dataCy}
      onClick={onClick}
      startIcon={startIcon}
      isLabelTransformDisabled
    >
      {buttonText}
    </PSButton>
  )
}
