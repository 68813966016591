export const Dashboard = {
  ordersInfo: 'Orders Info',
  billingInfo: 'Billing Info',
  supportRequests: 'Support Requests',
  pendingSubmission: 'Pending submission',
  scheduled: 'Scheduled',
  inReview: 'In Review',
  finalized: 'Finalized (Last 90 days)',
  undeliveredFinalReports: 'Undelivered Final Reports',
  pendingRevertBilling: 'Pending Revert Billing',
  pendingBillingInformation: 'Pending billing information',
  onHold: 'On Hold',
  allRequests: 'All requests',
  siteInfo: 'Site info',
  orders: 'Orders',
  ordersSupportRequests: 'Orders Support Requests',
  duplicatePatientMonitor: 'Duplicate Patient Records Monitor',
  duplicatesMonitorTitle: 'Duplicates (POL3 level)',
  duplicatesTooltipInfo:
    'Number of potential patients records duplications in the orders where Date of Service in the next 72 hours',
}
