import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { initialState, SITES_SLICE_NAME } from './constants'
import {
  createSite,
  fetchAutoForwardRulesDictionary,
  fetchSiteInfo,
  updateSite,
} from './controllers'

const sitesSlice = createSlice({
  name: SITES_SLICE_NAME,
  initialState,
  reducers: {
    clearSiteInfo: (state) => {
      state.siteInfo = initialState.siteInfo
      state.error = initialState.error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteInfo.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchSiteInfo.fulfilled, (state, { payload }) => {
        state.siteInfo = payload
        state.loadingState = LOADING_STATE.fulfilled
        state.error = null
      })
      .addCase(fetchSiteInfo.rejected, (state, { payload }) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = payload
      })
      .addCase(fetchAutoForwardRulesDictionary.pending, (state) => {
        state.autoForwardRulesDictionary.loadingState = LOADING_STATE.pending
      })
      .addCase(
        fetchAutoForwardRulesDictionary.fulfilled,
        (state, { payload }) => {
          state.autoForwardRulesDictionary.data = payload
          state.autoForwardRulesDictionary.loadingState =
            LOADING_STATE.fulfilled
        }
      )
      .addCase(fetchAutoForwardRulesDictionary.rejected, (state) => {
        state.autoForwardRulesDictionary.loadingState = LOADING_STATE.rejected
      })
    builder
      .addCase(createSite.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(createSite.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.error = null
      })
      .addCase(createSite.rejected, (state, { payload }) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = payload
      })
    builder
      .addCase(updateSite.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(updateSite.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.error = null
      })
      .addCase(updateSite.rejected, (state, { payload }) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = payload
      })
  },
})

export const { clearSiteInfo } = sitesSlice.actions

export default sitesSlice
