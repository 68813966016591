import { RootState } from 'store/store'

import { TOrderReportInformation, TReportTextsRs } from './types'

export const getOrderReport = (state: RootState) => state.orderReportInformation

export const getOrderReportInformation = (
  state: RootState
): TOrderReportInformation | undefined =>
  getOrderReport(state).reportInformation

export const getLegalCopies = (state: RootState) =>
  getOrderReport(state).legalCopies

export const getOrderReportCompareInformation = (
  state: RootState
): TReportTextsRs | undefined => getOrderReport(state).compareInformation

export const getOrderReportLoadingState = (state: RootState) =>
  getOrderReport(state).loadingState

export const getIsFinalLegalCopyReleasedData = (state: RootState) =>
  getOrderReport(state).orderReportVersionModalData

export const getOpenedReportModal = (state: RootState) =>
  getOrderReport(state).openedReportModal
