import {
  TBillingStatus,
  TBillingType,
  TBodyPart,
  TCPTCode,
  THoldReason,
  TModality,
  TOrderStatusRs,
  TProcedure,
  TQAReason,
  TUrgency,
} from 'services/dictionary/types'
import { IDatePeriod } from 'components/filters/DateRangeFilter/types'
import { TStateKey } from 'i18n/dictionaries/stateKeys'
import { DELIVERY_STATUS } from 'store/order/types'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { TProviderDisplayName } from 'store/providers/types'
import { TAG_TYPE, TTagRs } from 'store/tags/types'
import { components } from 'types/schemas'

export interface IOrderTableQueryData {
  sort?: string
  page: number
  size: number
}

export type TOrderTableFilters = components['parameters']['bffOrderFilterRq']

export type TOrdersTableParams = Partial<
  TOrderTableFilters & {
    billingStatusIds: number[]
  } & IOrderTableQueryData
>

export type TSiteNameRs = components['schemas']['SiteNameRs']

export type TSystemTag = Required<components['schemas']['SystemTag']>

export const OrderDeliveryStatusFilterMap = {
  FAILED_ATTEMPT: DELIVERY_STATUS.FAILED_ATTEMPT,
  PENDING_DELIVERY: DELIVERY_STATUS.PENDING_DELIVERY,
  SUCCESSFULLY_SENT: DELIVERY_STATUS.SUCCESSFULLY_SENT,
} as const

export type TOrderDeliveryStatusFilter = Array<{
  label: string
  name: keyof typeof OrderDeliveryStatusFilterMap
}>

export type TSystemTagColumnData = Omit<
  components['schemas']['BffSystemTagRs'],
  'systemTag'
> & { systemTag: TSystemTag }

export enum ALL_ORDERS_TABLE_DEFAULT_COLUMNS {
  ACCESSION_NUMBER = 'ACCESSION_NUMBER',
  URGENCY = 'URGENCY',
  DATE_OF_SERVICE = 'DATE_OF_SERVICE',
  PATIENT_NAME = 'PATIENT_NAME',
  PROCEDURE = 'PROCEDURE',
  ORDERING_PROVIDER = 'ORDERING_PROVIDER',
  ASSIGNED_RADIOLOGIST = 'ASSIGNED_RADIOLOGIST',
  RIS_STATUS = 'RIS_STATUS',
  TAGS = 'TAGS',
  SYSTEM_TAGS = 'SYSTEM_TAGS',
}

export enum ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS {
  PRO_SCAN_MRN = 'PRO_SCAN_MRN',
  CREATE_DATE = 'CREATE_DATE',
  SOURCE_ACCESSION = 'SOURCE_ACCESSION',
  SOURCE_MRN = 'SOURCE_MRN',
  COMPANY = 'COMPANY',
  BILLING_TYPE = 'BILLING_TYPE',
  BILLING_STATUS = 'BILLING_STATUS',
  SITE_NAME = 'SITE_NAME',
}

export type TAllOrderColumns =
  | ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS
  | ALL_ORDERS_TABLE_DEFAULT_COLUMNS

interface IOrderTableFiltersBasicData {
  dateOfService: {
    from: string | null
    to: string | null
    period: IDatePeriod | null
  }
  siteNames: Array<TSiteNameRs>
  bodyParts: TBodyPart[]
  modalities: TModality[]
  procedures: TProcedure[]
  tags: {
    [TAG_TYPE.ADMIN]: TTagRs[]
    [TAG_TYPE.CUSTOMER]: TTagRs[]
  }
}

export interface IAllOrdersFiltersData extends IOrderTableFiltersBasicData {
  urgencies: TUrgency[]
  cptCodes: TCPTCode[]
  physicians: Array<TProviderDisplayName>
  radiologists: Array<TProviderDisplayName>
  contributingReaders: Array<TProviderDisplayName>
  followUpDate: {
    from: string | null
    to: string | null
    period: IDatePeriod | null
  }
  billingTypes: TBillingType[]
  billingStatuses: TBillingStatus[]
  proscanMrnSearch: string
  proscanAccessionSearch: string
  sourceMrnSearch: string
  sourceAccessionSearch: string
  patientSearch?: string
  siteSearch?: string
  isInactiveStatuses: boolean
  orderStatuses: TOrderStatusRs[]
  deliveryStatuses: TOrderDeliveryStatusFilter
  systemTags: TSystemTag[]
  providerId?: string
}

export interface IAllOrdersTableFiltersData {
  columnsConfiguration: {
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.PRO_SCAN_MRN]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.CREATE_DATE]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_ACCESSION]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_MRN]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.COMPANY]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_TYPE]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_STATUS]: boolean
    [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SITE_NAME]: boolean
  }
  filtersData: IAllOrdersFiltersData
  queryData: IOrderTableQueryData
}

export interface IPreparationOrdersFiltersData
  extends IOrderTableFiltersBasicData {
  holdReasons: THoldReason[]
  urgencies: TUrgency[]
  systemTags: TSystemTag[]
  search: string
}

export interface IPreparationOrdersTableFiltersData {
  filtersData: IPreparationOrdersFiltersData
  queryData: Required<IOrderTableQueryData>
}

export interface IAssignmentOrdersFiltersData
  extends IOrderTableFiltersBasicData {
  urgencies: TUrgency[]
  hasAssignedRadiologist: boolean
  radiologists: Array<TProviderDisplayName>
  states: TStateKey[]
  physicians: Array<TProviderDisplayName>
  followUpDate: {
    from: string | null
    to: string | null
    period: IDatePeriod | null
  }
  systemTags: TSystemTag[]
}

export interface IAssignmentOrdersTableFiltersData {
  filtersData: IAssignmentOrdersFiltersData
  queryData: Exclude<IOrderTableQueryData, 'sort' | 'search'>
}

export interface IReportQAOrdersFiltersData
  extends IOrderTableFiltersBasicData {
  urgencies: TUrgency[]
  radiologists: Array<TProviderDisplayName>
  contributingReaders: Array<TProviderDisplayName>
  reportQAReasons: TQAReason[]
  systemTags: TSystemTag[]
  search: string
  hideInReview?: boolean
}

export interface IReportQAOrdersTableFiltersData {
  filtersData: IReportQAOrdersFiltersData
  queryData: Required<IOrderTableQueryData>
}

export type TCompanyFilter = components['schemas']['BffCompanySitesRs']

export interface IBillingOrdersFiltersData extends IOrderTableFiltersBasicData {
  patientDayOfBirth: null | string
  radiologists: Array<TProviderDisplayName>
  cptCodes: TCPTCode[]
  companies: TCompanyFilter[]
  billingTypes: TBillingType[]
  billingStatuses: TBillingStatus[]
  insuranceGroupNames: string[]
  physicians: Array<TProviderDisplayName>
  search: string
  systemTags: TSystemTag[]
}

export interface IBillingOrdersTableFilterData {
  filtersData: IBillingOrdersFiltersData
  queryData: Required<IOrderTableQueryData>
}

export interface IState {
  [ORDERS_TABLE_TAB.ALL]: IAllOrdersTableFiltersData
  [ORDERS_TABLE_TAB.PREPARATION]: IPreparationOrdersTableFiltersData
  [ORDERS_TABLE_TAB.ASSIGNMENT]: IAssignmentOrdersTableFiltersData
  [ORDERS_TABLE_TAB.REPORT_QA]: IReportQAOrdersTableFiltersData
  [ORDERS_TABLE_TAB.BILLING]: IBillingOrdersTableFilterData
}
