import { FC, ReactNode } from 'react'

import { PSTabPanelContent } from './components/PSTabPanelContent'

interface ITabPanelProps {
  children?: ReactNode
  index: number | string
  value: number | string
  padding?: number
  onTabContentMounted?: () => void
}

export const PSTabPanel: FC<ITabPanelProps> = ({
  children,
  value,
  index,
  padding = 3,
  onTabContentMounted,
}) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <PSTabPanelContent
          onTabContentMounted={onTabContentMounted}
          padding={padding}
        >
          {children}
        </PSTabPanelContent>
      )}
    </div>
  )
}
