import dayjs from 'dayjs'
import {
  IDatePeriod,
  PERIODS_ENUM,
} from 'components/filters/DateRangeFilter/types'
import { formatEndDateRangeFilterValueForBe } from 'utils/helpers/date/formatEndDateRangeFilterValueForBe'
import { formatStartDateRangeFilterValueForBe } from 'utils/helpers/date/formatStartDateRangeFilterValueForBe'
import { subDay } from 'utils/helpers/date/subDay'

export const DATE_PERIODS: IDatePeriod[] = [
  {
    key: PERIODS_ENUM.TODAY,
    label: 'domains.RISOrders.today',
  },
  {
    key: PERIODS_ENUM.LAST_30_DAYS,
    label: 'domains.RISOrders.last30Days',
  },
  {
    key: PERIODS_ENUM.LAST_90_DAYS,
    label: 'domains.RISOrders.last90Days',
  },
  {
    key: PERIODS_ENUM.THIS_WEEK,
    label: 'domains.RISOrders.thisWeek',
  },
]

export const customPeriodOption: IDatePeriod = {
  key: PERIODS_ENUM.CUSTOM,
  label: 'domains.RISOrders.custom',
}

export const calculatePeriod: Partial<
  Record<
    PERIODS_ENUM,
    () => {
      dateFrom: string
      dateTo: string
    }
  >
> = {
  [PERIODS_ENUM.TODAY]: () => {
    const today = dayjs()
    return {
      dateFrom: formatStartDateRangeFilterValueForBe(today),
      dateTo: formatEndDateRangeFilterValueForBe(today),
    }
  },
  [PERIODS_ENUM.LAST_30_DAYS]: () => {
    const today = dayjs()
    return {
      dateFrom: formatStartDateRangeFilterValueForBe(subDay(today, 30)),
      dateTo: formatEndDateRangeFilterValueForBe(today),
    }
  },
  [PERIODS_ENUM.LAST_90_DAYS]: () => {
    const today = dayjs()
    return {
      dateFrom: formatStartDateRangeFilterValueForBe(subDay(today, 90)),
      dateTo: formatEndDateRangeFilterValueForBe(today),
    }
  },
  [PERIODS_ENUM.THIS_WEEK]: () => {
    const today = dayjs()
    return {
      dateFrom: today.startOf('week').toISOString(),
      dateTo: today.endOf('week').toISOString(),
    }
  },
}
