import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { BreadcrumbsContext } from 'components/BreadcrumbsProvider/BreadcrumbsContext'

export const useGetBreadcrumbsRouteBackHandler = () => {
  const breadcrumbs = useContext(BreadcrumbsContext)
  const navigate = useNavigate()

  const onBreadcrumbsRouteBackHandler = () => {
    if (breadcrumbs) {
      const previousPage = breadcrumbs[breadcrumbs.length - 2]

      navigate(previousPage.href, {
        state: {
          breadcrumbs: breadcrumbs.slice(0, -2),
        },
      })
    } else {
      throw new Error(
        'BreadcrumbsContext has to be used within <BreadcrumbsContext.Provider>'
      )
    }
  }

  return { onBreadcrumbsRouteBackHandler }
}
