import { FormEvent, ReactNode } from 'react'
import {
  StyledForm,
  StyledPSButton,
} from 'components/SearchForm/SearchForm.styled'

interface ISearchFormProps {
  children: ReactNode
  onSubmit: () => void
  hideSubmitButton?: boolean
}

export const SearchForm = ({
  children,
  onSubmit,
  hideSubmitButton,
}: ISearchFormProps) => {
  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <StyledForm onSubmit={onSubmitHandler}>
      {children}
      {hideSubmitButton ? null : <StyledPSButton type='submit' />}
    </StyledForm>
  )
}
