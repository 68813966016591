import { TPracticesAllResponse, TPracticesFilters } from 'store/practice/types'
import { RootState } from 'store/store'

const getPractices = (state: RootState) => state.practices

export const getPracticesTableData = (state: RootState) =>
  getPractices(state).practicesPage.tableData

export const getPracticesFiltersOptions = (
  state: RootState
): TPracticesFilters => getPractices(state).practicesPage.filtersOptions

export const getPracticesList = (state: RootState): TPracticesAllResponse =>
  getPractices(state).practicesCatalog

export const getPracticePageData = (state: RootState) =>
  getPractices(state).practicePage
