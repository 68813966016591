import { matchPath, useLocation } from 'react-router-dom'
import { TPath } from 'types/general'

export const useRouteMatch = (patterns: readonly TPath[]) => {
  const { pathname } = useLocation()

  for (const pattern of patterns) {
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}
