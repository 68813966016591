import { TProcedure } from 'services/dictionary/types'
import { TServiceError } from 'utils/api/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export type TProcedureID = components['schemas']['ProcedureCreationRs']

export type TProcedureCreate = components['schemas']['ProcedureCreationRq']
export type TProcedureUpdate = components['schemas']['ProcedureUpdateRq']

export interface IState {
  loadingState: LOADING_STATE
  procedure?: TProcedure
  error?: null | TServiceError
}

export enum PROCEDURE_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ASSESSMENT_REASON_ENUM {
  MEDICAL_CLEARANCE = 'MEDICAL_CLEARANCE',
  SCAN_3D = 'SCAN_3D',
}
