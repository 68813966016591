import {
  GENERAL_ROLES,
  PATIENTS_ROLES,
  SHARED_STUDIES_ROLES,
  SITE_ADMIN_AND_SUPER_USER_ROLES,
  SITE_AND_SUPER_USER_ROLES,
  SUPER_USER_ROLES,
  USER_ROLES,
} from 'constants/userRoles'

export const routes = {
  main: {
    path: '/',
    roles: SITE_AND_SUPER_USER_ROLES.concat(USER_ROLES.PROVIDER_ASSISTANT),
  },
  companies: {
    root: { path: '/companies', roles: SUPER_USER_ROLES },
    create: { path: '/companies/new', roles: SUPER_USER_ROLES },
    details: { path: '/companies/:companyId', roles: SUPER_USER_ROLES },
    companySite: {
      path: '/companies/:companyId/sites/:siteId',
      roles: SUPER_USER_ROLES,
    },
    companySiteReportConfiguration: {
      path: '/companies/:companyId/sites/:siteId/report',
      roles: SUPER_USER_ROLES,
    },
    siteCreate: {
      path: '/companies/:companyId/sites/new',
      roles: SUPER_USER_ROLES,
    },
  },
  practices: {
    root: { path: '/practices', roles: SUPER_USER_ROLES },
    create: { path: '/practices/new', roles: SUPER_USER_ROLES },
    practiceById: { path: '/practices/:practiceId', roles: SUPER_USER_ROLES },
  },
  providers: {
    root: { path: '/providers', roles: SITE_ADMIN_AND_SUPER_USER_ROLES },
    providerById: {
      path: '/providers/:providerId',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    create: { path: '/providers/new', roles: SITE_ADMIN_AND_SUPER_USER_ROLES },
    onCallAssignment: {
      path: '/providers/assignment',
      roles: SUPER_USER_ROLES,
    },
    radiologists: { path: '/providers/radiologists', roles: SUPER_USER_ROLES },
    referringProvider: {
      path: '/providers/referring-provider',
      roles: SITE_ADMIN_AND_SUPER_USER_ROLES,
    },
  },
  supportRequests: {
    root: { path: '/support', roles: SITE_AND_SUPER_USER_ROLES },
    supportRequestById: {
      path: '/support/:supportId',
      roles: GENERAL_ROLES,
    },
  },
  dictionaries: {
    root: { path: '/dictionaries', roles: SITE_AND_SUPER_USER_ROLES },
    procedures: {
      root: { path: '/dictionaries/procedures', roles: SUPER_USER_ROLES },
      create: {
        path: '/dictionaries/procedures/new',
        roles: [USER_ROLES.SUPER_USER] as USER_ROLES[],
      },
      details: {
        path: '/dictionaries/procedures/:procedureId',
        roles: SUPER_USER_ROLES,
      },
    },
    flags: {
      root: { path: '/dictionaries/flags', roles: SUPER_USER_ROLES },
      create: { path: '/dictionaries/flags/new', roles: SUPER_USER_ROLES },
      details: { path: '/dictionaries/flags/:flagId', roles: SUPER_USER_ROLES },
    },
    tags: {
      root: { path: '/dictionaries/tags', roles: SITE_AND_SUPER_USER_ROLES },
    },
    dicomDestinations: {
      root: { path: '/dictionaries/destinations', roles: SUPER_USER_ROLES },
      destinationCreate: {
        path: '/dictionaries/destinations/new',
        roles: SUPER_USER_ROLES,
      },
      destinationEdit: {
        path: '/dictionaries/destinations/:destinationId',
        roles: SUPER_USER_ROLES,
      },
    },
    dicomDestinationGroup: {
      root: {
        path: '/dictionaries/destination-group',
        roles: SUPER_USER_ROLES,
      },
      destinationGroupCreate: {
        path: '/dictionaries/destination-group/new',
        roles: SUPER_USER_ROLES,
      },
      destinationGroupEdit: {
        path: '/dictionaries/destination-group/:destinationGroupId',
        roles: SUPER_USER_ROLES,
      },
    },
  },
  profile: {
    root: { path: '/profile' },
  },
  orders: {
    root: { path: '/orders', roles: GENERAL_ROLES },
    all: { path: '/orders/all', roles: GENERAL_ROLES },
    preparation: { path: '/orders/preparation', roles: SUPER_USER_ROLES },
    assignments: { path: '/orders/assignments', roles: SUPER_USER_ROLES },
    reportQa: { path: '/orders/report-qa', roles: SUPER_USER_ROLES },
    billing: { path: '/orders/billing', roles: SITE_AND_SUPER_USER_ROLES },
    paperwork: { path: '/orders/paperwork', roles: SUPER_USER_ROLES },
    paperworkAttachment: {
      path: '/orders/paperwork/:id',
      roles: SUPER_USER_ROLES,
    },
    create: { path: '/orders/new', roles: SITE_AND_SUPER_USER_ROLES },
    orderEdit: {
      path: '/orders/:accessionNumber',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    orderPreview: {
      path: '/orders/:accessionNumber/preview',
      roles: GENERAL_ROLES,
    },
    orderAudit: {
      path: '/orders/:accessionNumber/audit',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    orderAttachment: {
      path: '/orders/:accessionNumber/order-attachment',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    patientAudit: {
      path: '/orders/:accessionNumber?/:companyId?/patient-audit/:proscanMrn',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    orderBillingInformationPreview: {
      path: '/orders/:accessionNumber/billing-information',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    orderBillingInformationEdit: {
      path: '/orders/:accessionNumber/billing-information/edit',
      roles: SITE_AND_SUPER_USER_ROLES,
    },
    orderReportInformation: {
      path: '/orders/:accessionNumber/report',
      roles: SUPER_USER_ROLES,
    },
    orderReportInformationCompare: {
      path: '/orders/:accessionNumber/compare',
      roles: SUPER_USER_ROLES,
    },
    radiologistAssignment: {
      path: '/orders/add-assignment',
      roles: [
        USER_ROLES.PRACTICE_USER,
        USER_ROLES.SITE_USER_ADMIN,
        USER_ROLES.SITE_USER,
        USER_ROLES.SUPER_USER,
        USER_ROLES.SYS_ADMIN,
        USER_ROLES.PROCTOR,
      ] as USER_ROLES[],
    },
  },
  users: {
    root: { path: '/users', roles: SITE_ADMIN_AND_SUPER_USER_ROLES },
    create: { path: '/users/new', roles: SITE_ADMIN_AND_SUPER_USER_ROLES },
    profile: { path: '/users/:userId', roles: SITE_ADMIN_AND_SUPER_USER_ROLES },
  },
  patients: {
    root: { path: '/patients', roles: SITE_AND_SUPER_USER_ROLES },
    patientById: { path: '/patients/:patientId', roles: PATIENTS_ROLES },
  },
  sharedStudies: {
    root: { path: '/shared-studies', roles: SHARED_STUDIES_ROLES },
    sharedStudyPreview: {
      path: '/shared-studies/:accessionNumber',
      roles: SHARED_STUDIES_ROLES,
    },
  },
  403: { path: '/403' },
  404: { path: '/404' },
  testError: { path: '/test-error', roles: SUPER_USER_ROLES },
} as const
