import { FC, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { BreadcrumbsContext } from './BreadcrumbsContext'
import { IBreadcrumb } from './types'

interface IBreadcrumbsProviderProps {
  defaultBreadcrumbs: IBreadcrumb[]
  entityBreadcrumb: IBreadcrumb
  children: ReactNode
}

export const BreadcrumbsProvider: FC<IBreadcrumbsProviderProps> = ({
  children,
  defaultBreadcrumbs,
  entityBreadcrumb,
}) => {
  const { state } = useLocation()

  const breadcrumbs = (
    state?.breadcrumbs ? state.breadcrumbs : defaultBreadcrumbs
  ).concat(entityBreadcrumb)

  return (
    <BreadcrumbsContext.Provider value={breadcrumbs}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}
