import type { IState } from 'store/dashboard/types'
import { LOADING_STATE } from 'types/general'

export const initialState: IState = {
  loadingDashboardInfoState: LOADING_STATE.idle,
  loadingDuplicatesCountState: LOADING_STATE.idle,
  duplicatesCount: 0,
  info: undefined,
  adminInfo: undefined,
  providerAssistantInfo: undefined,
}

export const DASHBOARD_SLICE_NAME = 'dashboard'
