import { getProviderOptionLabel } from 'pages/orders/OrdersList/helpers/getProviderOptionLabel'
import { removeAssignedPhysicianFilterOption } from 'store/filters/orders/ordersSlice'
import { useAppDispatch } from 'store/hooks'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { TProviderDisplayName } from 'store/providers/types'

export const useGetAppliedAssignedPhysicianFilterPills = (
  filterData: Array<TProviderDisplayName>,
  tableName:
    | ORDERS_TABLE_TAB.ASSIGNMENT
    | ORDERS_TABLE_TAB.BILLING
    | ORDERS_TABLE_TAB.ALL
) => {
  const dispatch = useAppDispatch()

  return filterData.map((physician) => ({
    id: `${physician.providerId}-${physician.lastName}`,
    label: getProviderOptionLabel(physician),
    deleteFilterHandler: () =>
      dispatch(
        removeAssignedPhysicianFilterOption({
          id: physician.providerId as string,
          tableName,
        })
      ),
  }))
}
