import { useTranslation } from 'react-i18next'
import { SUPPORT_REQUEST_TYPES } from 'store/support/types'

export const useGetSupportRequestTypeFilterOptions = (
  shouldDisplayInternalType: boolean
) => {
  const { t } = useTranslation()

  return [
    ...(shouldDisplayInternalType
      ? [
          {
            id: SUPPORT_REQUEST_TYPES.INTERNAL,
            name: t('domains.Support.Internal'),
          },
          {
            id: SUPPORT_REQUEST_TYPES.AUTOMATIC,
            name: t('domains.Support.Automatic'),
          },
        ]
      : []),
    {
      id: SUPPORT_REQUEST_TYPES.CUSTOMER,
      name: t('domains.Support.Customer'),
    },
    {
      id: SUPPORT_REQUEST_TYPES.PROSCAN,
      name: t('domains.Support.ProScan'),
    },
  ]
}
