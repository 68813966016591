import { Box, CircularProgress, styled } from '@mui/material'

import { PSButton } from '../PSButton'

export const StyledTextWithActionContainer = styled(PSButton, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading: boolean }>(({ isLoading }) => ({
  position: 'relative',
  fontSize: '14px',
  textDecoration: 'none',
  ...(!isLoading && { cursor: 'pointer' }),
  display: 'flex',
  alignItems: 'center',
  '&.MuiButton-root': {
    textTransform: 'none',
  },
}))

export const StyledActionButtonContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading: boolean }>(({ isLoading }) => ({
  position: 'relative',
  ...(!isLoading && { cursor: 'pointer' }),
  display: 'flex',
  alignItems: 'center',
}))

export const StyledButtonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading: boolean }>(({ isLoading }) => ({
  opacity: isLoading ? 0 : 1,
}))

export const StyledProgressIcon = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
}))
