import { IState, MULTIPLE_TYPE_ENUM } from 'store/patient/types'
import { LOADING_STATE } from 'types/general'

export const SLICE_NAME = 'patient'

export const initialState: IState = {
  loadingState: LOADING_STATE.idle,
  compareInfo: {
    info: undefined,
    multipleInfo: {
      duplicateIds: [],
      resourceId: '',
      duplicateIdsPosition: MULTIPLE_TYPE_ENUM.TARGET,
    },
    ordersList: {},
    loadingState: LOADING_STATE.idle,
  },
}

export const defaultOrderListState = {
  loadingState: LOADING_STATE.idle,
  pageable: {
    size: 10,
    page: 0,
  },
}
