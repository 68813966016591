import { calculatePeriod } from '../constants'
import { PERIODS_ENUM } from '../types'

export const getDatesForSelectedPeriod = (selectedPeriod: PERIODS_ENUM) => {
  const calculateDates = calculatePeriod[selectedPeriod]
  if (!calculateDates) {
    throw new Error('Invalid period selected')
  }
  return calculateDates()
}
